import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>

    <a href="/home">home</a><br />
    <a href="/legal">legal</a><br />
    <a href="/style-guide">*style guide</a><br />
    <a href="/style-form">*style form</a><br /><br />

    /sessions/<br />
    <a href="/sessions/login">login</a><br />
    <a href="/sessions/signup">signup</a><br />
    <a href="/sessions/link-expired">link-expired</a><br />
    <a href="/sessions/email-validation">email-validation</a><br />
    <a href="/sessions/account-activated">account-activated</a><br />
    <a href="/sessions/onboarding-start">onboarding-start</a><br />
    <a href="/sessions/password-reset">password-reset</a><br />
    <a href="/sessions/password-reset-new">password-reset-new</a><br />
    <a href="/sessions/password-reset-link">password-reset-link</a><br />
    <a href="/sessions/password-reset-link-expired">password-reset-link-expired</a><br />
    <a href="/sessions/password-reset-confirm">password-reset-confirm</a><br /><br />

    /profile/<br />
    <a href="/profile/add-car">add-car</a><br />
    <a href="/profile/add-car-added">add-car-added</a><br />
    <a href="/profile/add-car-already-associated">add-car-already-associated</a><br />
    <a href="/profile/add-car-kms">add-car-kms</a><br />
    <a href="/profile/add-car-last-service">add-car-last-service</a><br />
    <a href="/profile/congratulations">congratulations</a><br />
    <a href="/profile/preferences">preferences</a><br />
    <a href="/profile/set-dealer-confirm">set-dealer-confirm</a><br />
    <a href="/profile/set-dealer-find">set-dealer-find</a><br /><br />


  </Layout>
)

export default NotFoundPage
